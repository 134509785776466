import React from 'react'
import styled from 'styled-components'
import AppBody from '../../pages/AppBody'

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 5px;
  text-align: center;
  position: relative;
  bottom: 0;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
`

export default function Footer() {
  return (
    <AppBody>
    <FooterWrapper>
<b>SAS Contract (Ethereum, BNB Chain):</b><br/>
0x28BE7E8cD8125CB7A74D2002A5862E1bfd774cd9<br/>
<br/>
<b>SAS Contract (Shibarium):</b><br/>
0x7E315C269F7849F80824755A666DC6fb4Ba8BEe1<br/>
<br/>
<b>Maximum Swap Amount:</b> 40.000.000.000.000<br/>
<br/>
<br/>
    </FooterWrapper>
    <FooterWrapper>
<b>SAS LIVE SALE</b><br/>
<br/>
For the amount of ETH, BONE, BNB you send to the following contract addresses, the SAS will be automatically sent to your wallet.<br/>
<br/>
<b>Ethereum (ETH) - BNB Chain (BNB):</b><br/>
0xede0CE9F198ac1861b6Ab23936C8B164E1F06a36<br/>
<br/>
<b>Shibarium (BONE):</b><br/>
0x2A58E034E61eCFc8b33Ef8F6307Bd29E033b69df<br/>
<br/>
<i>100% SAS payback (buying automatic SAS with 1%)</i><br/>
<br/>
<br/>
© 2025 SASSWAP
    </FooterWrapper>
    </AppBody>
  )
}
